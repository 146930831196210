import React, {useState, useEffect}   from 'react'
import {Navigate } from "react-router-dom"; 

import { Container, Header, Divider, Image, Grid } from 'semantic-ui-react';

import { useMatomo } from '@jonkoops/matomo-tracker-react'


import {useWindowWidth} from '../global/Global'
import BackgroundHeaderBlock from '../components/BackgroundHeaderBlock'


import Initial_facebook from '../assets/landing/Initial_facebook.png'; 
import Initial_contact from '../assets/landing/Initial_contact.png'; 

import ComicPanels_1 from '../assets/landing/ComicPanels_1.png';
import ComicPanels_2 from '../assets/landing/ComicPanels_2.png';
import ComicPanels_3 from '../assets/landing/ComicPanels_3.png';
import ComicPanels_4 from '../assets/landing/ComicPanels_4.png';
import ComicPanels_5 from '../assets/landing/ComicPanels_5.png';

import ComicPanels_FAQ from '../assets/landing/ComicPanels_FAQ.png';

import ComicPanels_Goals from '../assets/landing/ComicPanels_Goals.png';

import versioning from '../versioning.json';

const App = (props) => {
  const [doFAQ, setFAQ] = useState(false)
  const [doGoals, setGoals] = useState(false)
  const [doContact, setContact] = useState(false)

    const  width  = useWindowWidth();

    const { trackPageView } = useMatomo()

    // Track page view
    useEffect(() => {
      trackPageView({
        documentTitle: 'Landing page',
      })

      //  @ts-ignore
      if (window.FB !== undefined) {
        //  @ts-ignore
        window.FB.XFBML.parse();
      }
    })    


    function goFAQ() {
      setFAQ(true)
    }    

    function goGoals() {
      setGoals(true)
    } 

    function goContact() {
      setContact(true)
    } 


    function ContactImage(props) {
      if (props.spaced ) {
        return (
          <Image src={Initial_contact} spaced="left" onClick={goContact} className='pointer'/>
        )
      } else {
        return (
          <Image src={Initial_contact} onClick={goContact} className='pointer'/>
        )
      }

    }

    function FAQImage() {
      return (
        <Image src={ComicPanels_FAQ}  onClick={goFAQ} className='pointer'/>
      )
    }

    function GoalsImage(mobile) {
      return (
        <Image src={ComicPanels_Goals} onClick={goGoals} className='pointer'/>
      )
    }

    function FacebookLike(props) {
      return (
        <div className="fb-like fbcentre" data-href="https://www.facebook.com/mypicturesmatter" 
        data-width="" data-layout="button" data-action="like" data-size={props.size} data-share="false"></div>
      )
    }
 
    return (      
      <Container textAlign='center'>
        {doFAQ && 
          <Navigate to="/faq"/>
        }

        {doGoals && 
          <Navigate to="/goals"/>
        }        

        {doContact && 
          <Navigate to="/contactus"/>
        }             

          <BackgroundHeaderBlock width={width}/>                   

          <Grid> 
            <Grid.Row only="tablet computer"></Grid.Row>

            {/* ------------------------------------------------------------------
                Headings 
                ------------------------------------------------------------------*/}
            <Grid.Row textAlign='center' only='tablet computer'>
              <Grid.Column width={16} >
                <Header textAlign='center'  size='huge' className='humongous'>Submissions to My Pictures Matter have closed.</Header>
              </Grid.Column>
            </Grid.Row>   

            <Grid.Row textAlign='center' only='mobile'>
              <Grid.Column width={16} >
                <p/>
                <Header textAlign='center'  size='large' >Submissions to My Pictures Matter have closed.</Header>
              </Grid.Column>
            </Grid.Row>      
          </Grid>

            {/* ------------------------------------------------------------------
                Notice
                ------------------------------------------------------------------*/} 
          <p></p>   
          <Container textAlign='left'>
            <p> 
              Thank you to everyone who has contributed childhood photos to this project! Collectively, your participation has enabled us to compile a fully consented dataset of everyday childhood images that will be used by researchers at Monash University’s AiLECS lab to improve the efficacy of machine learning to counter child sexual exploitation. 
            </p>
            <p> <b>
              Images you have provided to the dataset will not be used without your consent by any other parties, or for any purposes other than machine learning research with a clear and specific intent of countering child sexual exploitation.
            </b>            </p>

            <Header   size='medium' >What happens now?</Header>
            
            <p> 
              AiLECS is currently preparing the dataset for research use. This means: 
            </p>
              <ul>
                <li>
                  verifying the number of people in each image; 
                </li>
                <li>
                confirming positions marked on submissions correspond to location of actual persons in the image;
                </li>
                <li>
                checking use consent is affirmed for each person depicted;
                </li>
                <li>
                standardising contributor-supplied age data (to be consistently expressed as a numerical age in years);
                </li>
                <li>
                removing any images that do not meet criteria for inclusion in this dataset;
                </li>
                <li>
                cropping to exclude image borders that are not part of original photos;
                </li>
                <li>
                validating a dataset documentation package to guide research use.
                </li>
              </ul>

            <p>
              We will provide details of research activities that make use of the My Pictures Matter dataset on this page and/or on the <a href="https://ailecs.org/">AiLECS website</a>. 
            </p>

            <Header   size='medium' >It’s OK to change your mind! </Header>

            <p>
            You are free to withdraw consent for the use of photos you have contributed at any time. If you inform us that consent for research use has changed, we will issue an updated version of the dataset that does not include the relevant image(s), and this updated version of the dataset will be used for new research activities commencing after the date of the change request. If you have any questions about how the dataset is being used, or if you wish to revoke use consent for images, please get in touch at <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a>.
            </p>
            <p></p>
            <Divider/>
            <p></p>
          </Container> 
          
          <Grid>
            {/* ------------------------------------------------------------------
                Comic panels computer
                ------------------------------------------------------------------*/}                                     

            <Grid.Row columns='equal' only='tablet computer'className="comic-row" >
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_1} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_2} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_3} />
              </Grid.Column>
            </Grid.Row>     

            <Grid.Row columns='equal' only='tablet computer' className="comic-row">
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_4} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_5} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <FAQImage />
              </Grid.Column>
            </Grid.Row>   

            <Grid.Row only='tablet computer' columns={3} centered className="comic-row" textAlign='center'>
              <Grid.Column centered textAlign='center' className="comic-column">
                <GoalsImage />
              </Grid.Column>
            </Grid.Row>                                                       

            {/* ------------------------------------------------------------------
                Comic panels mobile
                ------------------------------------------------------------------*/}                                     

            <Grid.Row columns='equal' only='mobile' className="comic-row">
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_1} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_2} />
                </Grid.Column>
            </Grid.Row>      

            <Grid.Row columns='equal' only='mobile' className="comic-row">
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_3} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_4} />
                </Grid.Column>
            </Grid.Row>   

            <Grid.Row columns='equal' only='mobile' className="comic-row">
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_5} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <FAQImage />
                </Grid.Column>
            </Grid.Row>       

            <Grid.Row centered only='mobile' className="comic-row">
                <Grid.Column width="8">
                  <GoalsImage />
                </Grid.Column>
            </Grid.Row>    

            
            {/* ------------------------------------------------------------------
                Socials
                ------------------------------------------------------------------*/}            
            <Grid.Row only='tablet computer' columns={4} centered className="comic-row" textAlign='center'>
              <Grid.Column centered textAlign='center' className="comic-column">
                <ContactImage />
              </Grid.Column>
            </Grid.Row>    

            <Grid.Row textAlign='center' centered verticalAlign='top' className="comic-row" only='mobile'>               
              <Grid.Column width={10} >
              </Grid.Column>
            </Grid.Row>   

            <Grid.Row centered only='mobile' className="comic-row">
                <Grid.Column width="8">
                  <ContactImage />
                </Grid.Column>
            </Grid.Row> 
                                           
          </Grid>   
          <Divider/>

          <Container textAlign='left'>
            <p className="tiny-text">
            This research is being conducted by <a href="https:/ailecs.org/">AiLECS Lab</a>, a collaboration between Monash University and the Australian Federal Police. The project has received funding from the Westpac Safer Children Safer Communities Fund and has approval from the Monash University Human Research Committee (Project ID: 31436). Content that you share with us will be stored and managed by Monash University in accordance with guidelines set out in the <a href="https://www.nhmrc.gov.au/about-us/publications/national-statement-ethical-conduct-human-research-2007-updated-2018">Australian National Statement on Ethical Conduct in Human Research</a>. If you would like more information regarding any aspect of this project, email the researchers at <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a>, or contact the project’s Chief Investigator: <b>Associate Professor Campbell Wilson, Monash University</b>, Department of Data Science and AI, Phone: +61 3 9903 1142, Email: <a href="mailto:campbell.wilson@monash.edu">campbell.wilson@monash.edu</a> 
            </p>
          </Container>
          <Container textAlign='right'>
            <p className="tiny-text">
            {`V${versioning.buildMajor}.${versioning.buildMinor}.${versioning.buildRevision}`}
            </p>
          </Container>
        </Container>
       ) 
}

export default App;